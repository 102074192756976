<template>
    <div class="sobre">
        <div class="container">
            <div class="col-12 col-xl-10 offset-xl-1">
                <div class="row">
                    <div class="col-12 mt-5 text-center titulo">
                        <h1>Sobre</h1>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-6 offset-xl-3 mt-5">
                <div class="row">
                    <div class="col-12 form-group">
                        <p>
                            A Bia Truffas e Doces nasceu em 2018 com o propósito
                            de encantar e adoçar a vida das pessoas com os
                            doces.
                        </p>
                        <p>
                            Iniciando no ramo de truffas, expandimos ao longo do
                            tempo de acordo com as necessidades dos nossos
                            clientes.
                        </p>
                        <p>
                            Hoje em dia o cardápio possui uma variedade de
                            produtos, que vão desde truffas à torta de limão,
                            mousses, arroz doce, barrinhas 3D, brigadeiros e
                            pães de mel.
                        </p>
                        <p>
                            Temos como objetivo proporcionar bons momentos e
                            boas memórias, através dos doces, trazendo
                            qualidade, sabor e padronização em tudo que fazemos. E,
                            claro, sempre com um bom atendimento.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Sobre",
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
.sobre {
    @include background;
    @include form;

    .titulo {
        @include titulo;
    }

    h1 {
        text-transform: uppercase;
        color: $marrom;
        font-family: $roboto;
        font-weight: bold;
        font-style: normal;
        font-size: 50px;
        line-height: 50px;
    }

    h2 {
        color: $marrom;
        font-family: $roboto;
        font-weight: bold;
        font-style: normal;
        font-size: 22px;
        line-height: 22px;
    }

    p {
        color: $marrom;
        font-family: $roboto;
        font-weight: bold;
        font-style: normal;
        font-size: 17px;
        line-height: 24px;
    }
}
@media (max-width: 767.98px) {
    .sobre {
        padding: 50px 0 80px;
    }
}
</style>